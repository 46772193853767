import {
  IntegrationChannel,
  LookupEntityFieldType,
  NotifyConversationOpenedPayload,
  ThirdPartyWSMethod
} from './websocket/types';
import { ReactElement, useEffect } from 'react';

import Contact from '../../models/Contact';
import { useCassieWebsocket } from './websocket/CassieWebsocketContext';
import usePageVisible from './hooks/usePageVisible';
import { usePrevious } from './hooks/usePrevious';
import { datadogLogs } from '@datadog/browser-logs';

interface CassieContainerPropsInterface {
  contact: Contact | undefined;
  routingGroup: string;
}

function CassieContainer({ contact, routingGroup }: CassieContainerPropsInterface): ReactElement {
  const prevContact = usePrevious(contact);
  const { invoke } = useCassieWebsocket();

  const { isPageVisible } = usePageVisible();
  const prevIsPageVisible = usePrevious(isPageVisible);

  useEffect(() => {
    if (!prevIsPageVisible && isPageVisible) {
      void invoke(ThirdPartyWSMethod.NOTIFY_THIRD_PARTY_FOCUSED, {
        thirdPartyName: IntegrationChannel.CATO
      });
      datadogLogs.logger.info(`WEBSOCKET event sent to Cassie`, {
        event: ThirdPartyWSMethod.NOTIFY_THIRD_PARTY_FOCUSED
      });
    }
  }, [prevIsPageVisible, isPageVisible, invoke]);

  useEffect(() => {
    if (prevContact && !contact) {
      void invoke(ThirdPartyWSMethod.NOTIFY_CONVERSATION_CLOSED, { channel: IntegrationChannel.CATO });
      datadogLogs.logger.info(`WEBSOCKET event sent to Cassie`, {
        event: ThirdPartyWSMethod.NOTIFY_CONVERSATION_CLOSED
      });
    }
    if (!contact) return;

    const hasPhoneNumber = !!contact.phoneNumber;
    const wsPayload: Partial<NotifyConversationOpenedPayload> = {
      channel: IntegrationChannel.CATO,
      channelConversationId: contact.contactId,
      conversationSubsidiaryId: contact.groupSubsidiary ? parseInt(contact.groupSubsidiary) : undefined,
      routingGroup: routingGroup
    };
    if (hasPhoneNumber && contact.phoneNumber) {
      wsPayload.lookupCustomer = {
        fieldType: LookupEntityFieldType.TELEPHONE,
        fieldValue: contact.phoneNumber
      };
      wsPayload.visualIdentifier = contact.phoneNumber;
    }

    void invoke(ThirdPartyWSMethod.NOTIFY_CONVERSATION_OPENED, wsPayload as NotifyConversationOpenedPayload);
    datadogLogs.logger.info(`WEBSOCKET event sent to Cassie`, {
      event: ThirdPartyWSMethod.NOTIFY_CONVERSATION_OPENED
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, invoke]);

  return <></>;
}

export default CassieContainer;
