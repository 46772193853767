import { Box } from '@coolblue-development/becky';
import React, { useState, useEffect } from 'react';
import SelectOption from './SelectOption';

export interface AudioDevice {
  deviceId: string;
  label: string;
  kind: 'audioinput' | 'audiooutput';
}

const Settings = () => {
  const [audioInputDevices, setAudioInputDevices] = useState<AudioDevice[]>([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState<AudioDevice[]>([]);

  useEffect(() => {
    connect.core.getFrameMediaDevices(1000).then((devices: AudioDevice[]) => {
      const inputDevices = devices.filter(device => device.kind === 'audioinput');
      const outputDevices = devices.filter(device => device.kind === 'audiooutput');
      setAudioInputDevices(inputDevices);
      setAudioOutputDevices(outputDevices);
    });
  }, []);

  return (
    <Box width={300}>
      <SelectOption name="Speaker" devices={audioOutputDevices} label="Speaker" />
      <SelectOption name="Microphone" devices={audioInputDevices} label="Microphone" />
      <SelectOption name="Ringer" devices={audioOutputDevices} label="Ringer" />
    </Box>
  );
};

export default Settings;
