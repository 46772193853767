import * as React from 'react';
import * as SignalR from '@microsoft/signalr';
import { datadogLogs } from '@datadog/browser-logs';

import {
  IntegrationChannel,
  OutgoingEventPromiseType,
  OutgoingThirdPartyConnectorEventPayloads,
  ThirdPartyWSMethod
} from './types';

import config from '../../../config';
import { useAppContext } from '../../../AppContext';
import { usePrevious } from '../hooks/usePrevious';
import useSignalR from './useSignalR';

const { cassieWebsocketURL } = config;

type CassieWebsocketInvokeEntity<
  T extends keyof OutgoingThirdPartyConnectorEventPayloads & keyof OutgoingEventPromiseType
> = {
  method: T;
  payload: OutgoingThirdPartyConnectorEventPayloads[T];
  resolve?: (value?: OutgoingEventPromiseType[T] | PromiseLike<OutgoingEventPromiseType[T]>) => void;
  reject?: (reason?: Error | string) => void;
};

export type CassieWebsocketInvokeMethod = <
  T extends keyof OutgoingThirdPartyConnectorEventPayloads & keyof OutgoingEventPromiseType
>(
  method: T,
  payload?: OutgoingThirdPartyConnectorEventPayloads[T]
) => Promise<OutgoingEventPromiseType[T]>;

interface ContextInterface {
  connection: SignalR.HubConnection | undefined;
  invoke: CassieWebsocketInvokeMethod;
  connectionState: SignalR.HubConnectionState;
}

const CassieWebsocketContext: React.Context<ContextInterface> = React.createContext<ContextInterface>({
  connection: undefined,
  invoke: async <T extends keyof OutgoingThirdPartyConnectorEventPayloads & keyof OutgoingEventPromiseType>(): Promise<
    OutgoingEventPromiseType[T]
  > => Promise.resolve() as Promise<OutgoingEventPromiseType[T]>,
  connectionState: SignalR.HubConnectionState.Disconnected
});

const CassieWebsocketContextProvider: React.FC<{
  children: React.ReactNode;
  thirdParty?: IntegrationChannel;
}> = ({ children }): React.ReactElement => {
  const { agentEmail: userEmail } = useAppContext();
  const [connection, setConnection] = React.useState<SignalR.HubConnection>();

  // Keep track of the subscription status to the Cassie Hub (first connection / reconnection)
  const [hasSubscribed, setHasSubscribed] = React.useState<boolean>(false);

  // This queue is used to store the invokes that can't be sent because the websocket is not connected
  const [offlineInvokes, setOfflineInvokes] = React.useState<
    CassieWebsocketInvokeEntity<keyof OutgoingThirdPartyConnectorEventPayloads>[]
  >([]);

  // This hook is used to build the websocket connection (using the context connection to keep a unique instance)
  const {
    connection: wsConnection,
    start,
    connectionState
  } = useSignalR({
    connectionName: 'Cassie',
    url: cassieWebsocketURL,
    contextConnection: connection,
    autoConnect: false,
    maxConnectAttempts: 25
  });

  // Keep track if the websocket is connected
  const isConnectionEstablished = connection && connectionState === SignalR.HubConnectionState.Connected && userEmail;

  // Keep track if there are pending offline invokes in the queue
  const hasPendingInvokes = offlineInvokes.length > 0;

  // This function is used to call the offline invokes queue when the connection is established
  const callOfflineInvokes = React.useCallback(() => {
    if (!hasPendingInvokes || !isConnectionEstablished) return;

    offlineInvokes.forEach(item => {
      if (connection && item) {
        const { method, payload, resolve, reject } = item;
        const promise = connection.invoke(method, userEmail, payload);

        promise
          .then(value => {
            if (resolve) resolve(value);
          })
          .catch(err => {
            const errorMessage = payload
              ? `Error when invoking ${method} with payload`
              : `Error when invoking ${method}`;

            datadogLogs.logger.error(
              `Error obtaining lock ${errorMessage}`,
              { integration: 'cassie', standalone: true },
              err
            );
            if (reject) reject(err);
          });
      }
    });
    setOfflineInvokes([]);
  }, [offlineInvokes, connection, hasPendingInvokes, isConnectionEstablished, userEmail, hasSubscribed]);

  // Stores the connection in the context (to maintain a unique connection instance between renders)
  React.useEffect(() => {
    setConnection(wsConnection);
  }, [wsConnection]);

  // Keep track if the websocket is ready to connect with a valid user email
  const isConnectionReadyToConnectWithUserEmail =
    connection && connectionState === SignalR.HubConnectionState.Disconnected && userEmail;

  // Start the websocket connection only when the user email is set
  React.useEffect(() => {
    if (isConnectionReadyToConnectWithUserEmail) {
      void start();
    }
  }, [isConnectionReadyToConnectWithUserEmail, start]);

  // Wrapper for the WS invoke method from the websocket connection binding the user email (and offline queue mechanism)
  const invoke: CassieWebsocketInvokeMethod = React.useCallback(
    async (method, payload?) => {
      if (isConnectionEstablished) {
        // If connection is established, directly send the invoke
        const promise = payload ? connection.invoke(method, userEmail, payload) : connection.invoke(method, userEmail);

        // Return the promise for further chaining
        return promise.catch(err => {
          const errorMessage = payload ? `Error when invoking ${method} with payload` : `Error when invoking ${method}`;
          datadogLogs.logger.error(`${errorMessage}`, { integration: 'cassie', standalone: true }, err);

          throw err;
        });
      }
      // If connection is not established, store the invoke for later execution (by keeping resolve and reject callbacks)
      return new Promise((resolve, reject) => {
        const invokeArg = { method, payload, resolve, reject } as CassieWebsocketInvokeEntity<
          keyof OutgoingThirdPartyConnectorEventPayloads
        >;

        setOfflineInvokes(prevQueue => [...prevQueue, invokeArg]);
      });
    },
    [connection, isConnectionEstablished, userEmail]
  );

  // This makes sure to subscribe to the websocket when the connection is established
  // and to call the invocation queue if there is any
  const handleOnConnected = React.useCallback(() => {
    void invoke(ThirdPartyWSMethod.SUBSCRIBE, { thirdPartyName: IntegrationChannel.CATO }).then(() => {
      setHasSubscribed(true);
      callOfflineInvokes();
      datadogLogs.logger.info(`WEBSOCKET event sent to Cassie`, {
        event: ThirdPartyWSMethod.SUBSCRIBE
      });
    });
  }, [callOfflineInvokes, invoke]);

  // Handle the onConnected event (first connection / reconnection)
  React.useEffect(() => {
    if (!hasSubscribed && isConnectionEstablished) {
      handleOnConnected();
    }
  }, [isConnectionEstablished, hasSubscribed, handleOnConnected]);

  // Keep track of the previous connection status to detect reconnections
  const prevStatus = usePrevious(connectionState);

  // Keep track if the websocket has lost connection
  const hasLostConnection =
    prevStatus === SignalR.HubConnectionState.Connected && connectionState !== SignalR.HubConnectionState.Connected;

  // Ensure to track when the subscription was lost
  React.useEffect(() => {
    if (hasLostConnection) {
      setHasSubscribed(false);
    }
  }, [hasLostConnection]);

  // Stop the connection when the context is unmounted
  React.useEffect(() => {
    return () => {
      void connection?.stop();
    };
  }, [connection]);

  return (
    <CassieWebsocketContext.Provider
      value={{
        connection,
        connectionState,
        invoke
      }}
    >
      {children}
    </CassieWebsocketContext.Provider>
  );
};

export { CassieWebsocketContext, CassieWebsocketContextProvider };

// Hook for using the CassieWebsocketContext
export const useCassieWebsocket = (): ContextInterface => React.useContext(CassieWebsocketContext);
