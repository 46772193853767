import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Divider
} from '@coolblue-development/becky';
import { MouseEvent, useState } from 'react';
import { HeaderProps } from './Header';
import { ReactComponent as LogoutIcon } from '../../../assets/logout.svg';
import { useAppContext } from '../../../AppContext';
import { Page } from '../../../models/Page';

const ProfileMenu: React.FC<HeaderProps> = ({ instanceUrl }) => {
  const { setActivePage, agent } = useAppContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const agentName = agent?.getName();

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleCCPPage = (): void => {
    setActivePage(Page.CCP);
    handleClose();
  };

  const handleLogout = (): void => {
    const logoutUrl = `${instanceUrl}/connect/logout`;
    window.location.replace(logoutUrl);
  };

  return (
    <>
      <Button
        sx={{ background: 'transparent', color: 'inherit', font: 'inherit' }}
        preset="secondary"
        onClick={handleClick}
        aria-controls={open ? 'divider-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {agentName?.charAt(0)}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'divider-button' }}
      >
        <MenuItem onClick={handleClose}>
          <ListItemAvatar>
            <Avatar>{agentName?.charAt(0)}</Avatar>
          </ListItemAvatar>
          <ListItemText sx={{ padding: 2 }}>
            <Typography variant="h4">{agentName}</Typography>
            <Typography>Customer Service</Typography>
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleCCPPage}>
          <ListItemText>Old Panel</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <LogoutIcon />
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
