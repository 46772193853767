import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Loader, Label } from '@coolblue-development/becky';
import { useAppContext } from '../../../../../AppContext';
import { LoadingQuotes } from './LoadingQuotes';
import { ContactProps } from '../../../../../models/ContactProps';

const Incoming: React.FC<ContactProps> = (props: ContactProps) => {
  const { loader } = useAppContext();
  const [loadingMessage, setLoadingMessage] = useState<string>('');

  const handleAcceptChat = () => {
    props.contact.accept();
  };

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * LoadingQuotes.length);
    setLoadingMessage(LoadingQuotes[randomIndex]);
  }, []);

  return (
    <>
      <Box
        data-testid="incoming"
        sx={{ padding: '15px', marginBottom: '8px', borderRadius: '5px', border: '1px solid #00B900' }}
      >
        {!loader ? (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h5"> Customer </Typography>
              <Label color="green" variant="subtle">
                Incoming
              </Label>
            </Box>
            <Button sx={{ marginTop: '15px' }} onClick={handleAcceptChat} preset="callToAction" size="small" fullWidth>
              Accept chat
            </Button>
          </>
        ) : (
          <Loader logo=" " data-testid="loader" text={loadingMessage} minWidth={'100%'} sx={{ marginTop: '0px' }} />
        )}
      </Box>
    </>
  );
};

export default Incoming;
