import { Box } from '@coolblue-development/becky';
import React from 'react';

const NewMessageNotification = () => {
  return (
    <Box
      data-testid="new-message-notification"
      textAlign="center"
      sx={{
        color: '#666',
        overflow: 'hidden',
        paddingY: '20px',
        '&::before, &::after': {
          backgroundColor: '#DDD',
          content: '""',
          display: 'inline-block',
          height: '1px',
          position: 'relative',
          verticalAlign: 'middle',
          width: '50%'
        },
        '&::after': {
          left: '0.5em',
          marginRight: '-50%'
        },

        '&::before': {
          right: '0.5em',
          marginLeft: '-50%'
        }
      }}
    >
      New messages
    </Box>
  );
};

export default NewMessageNotification;
