import { Page } from '../../../models/Page';
import { Box } from '@coolblue-development/becky';
import { useAppContext } from '../../../AppContext';
import Settings from './Settings/Settings';
import Chat from './Chat/Chat';
import TopPanel from '../TopPanel/TopPanel';
import TelephonyPage from './Telephony/Page/TelephonyPage';
import CcpPanel from './CcpPanel';

interface PageContainerProps {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const PageContainer: React.FC<PageContainerProps> = ({ containerRef }) => {
  const { activePage } = useAppContext();

  return (
    <Box sx={{ width: '100%' }}>
      <TopPanel />
      {activePage === Page.SETTINGS && (
        <Box sx={{ paddingTop: '25px', marginLeft: '20px' }}>
          <Settings />
        </Box>
      )}
      {activePage === Page.CHAT && <Chat />}
      {activePage === Page.TELEPHONY && <TelephonyPage />}
      {containerRef && <CcpPanel containerRef={containerRef} />}
    </Box>
  );
};

export default PageContainer;
