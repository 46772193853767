import React from 'react';
import { Box, Fab, Label } from '@coolblue-development/becky';
import { ArrowDown } from '@coolblue-development/icons';

interface handleSrollToBottomProps {
  handleSrollToBottom: () => void;
  unreadMessageCount: number;
}

const ScrollToBottom: React.FC<handleSrollToBottomProps> = ({ handleSrollToBottom, unreadMessageCount }) => {
  return (
    <Box sx={{ position: 'sticky', bottom: '15px', textAlign: 'right', marginRight: '10px' }}>
      {unreadMessageCount > 0 && (
        <Label
          data-testid="unread-message-label"
          color="orange"
          sx={{ position: 'absolute', zIndex: 9999, transform: 'translateY(-40%)' }}
        >
          {unreadMessageCount}
        </Label>
      )}
      <Fab preset="secondary" onClick={handleSrollToBottom} data-testid="scroll-to-bottom-button">
        <ArrowDown />
      </Fab>
    </Box>
  );
};

export default ScrollToBottom;
