import { Box, OutlinedInput } from '@coolblue-development/becky';
import React from 'react';
import MagnifierIcon from '../../../Icons/MagnifierIcon';

interface QuickConnectsSearchProps {
  query: string;
  setQuery: (query: string) => void;
}

const QuickConnectsSearch: React.FC<QuickConnectsSearchProps> = ({ query, setQuery }) => {
  return (
    <Box
      sx={{
        marginY: '20px',
        position: 'relative',
        border: '1px solid #262930',
        '& .MuiOutlinedInput-root': {
          border: 'none'
        }
      }}
    >
      <OutlinedInput
        sx={{ width: '100%', paddingLeft: '40px' }}
        inputProps={{ style: { color: 'white' }, 'data-testid': 'quick-connects-search' }}
        placeholder="Search"
        value={query}
        onChange={e => setQuery(e.target.value)}
      />
      <Box sx={{ position: 'absolute', top: '60%', transform: 'translateY(-60%)', marginX: '10px' }}>
        <MagnifierIcon />
      </Box>
    </Box>
  );
};

export default QuickConnectsSearch;
