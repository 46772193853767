import { Box, Checkbox, Drawer, Grid, Typography } from '@coolblue-development/becky';
import { Fragment, useEffect, useState } from 'react';

import Accordion from './Accordion';
import { Paper } from '@mui/material';
import { useFeatureFlags } from './useFeatureFlags';

const ToolkitPanel = (): JSX.Element => {
  const [experimentDialogOpen, setExperimentDialogOpen] = useState(false);
  const { featureFlags, updateFeatureFlag } = useFeatureFlags();

  useEffect(() => {
    document.addEventListener('keydown', function (event) {
      let keySequence = '';

      if (event.metaKey || event.ctrlKey) {
        keySequence += 'cmd-';
      }

      if (event.shiftKey) {
        keySequence += 'shift-';
      }

      if (event.key !== 'Meta' && event.key !== 'Shift' && event.key !== 'Control' && event.key !== 'Alt') {
        keySequence += event.key.toLowerCase();
      }

      if (keySequence === 'cmd-shift-k' && !experimentDialogOpen) {
        setExperimentDialogOpen(true);
      }
    });
  }, [experimentDialogOpen]);

  const handleClose = (): void => {
    setExperimentDialogOpen(false);
  };

  return (
    <Box display="flex" gap={3}>
      <Drawer
        data-testid="close"
        title="Cato Toolkit"
        anchor={'left'}
        open={experimentDialogOpen}
        onClose={handleClose}
      >
        <Paper
          variant="outlined"
          style={{
            borderRadius: '12px',
            maxHeight: 'calc(100vh - 80px)',
            overflow: 'auto'
          }}
        >
          <Accordion title="Feature flags" accordion={{ defaultExpanded: true }}>
            <Grid container spacing={2}>
              {featureFlags.map(featureFlag => (
                <Fragment key={`${featureFlag.name}`}>
                  <Grid item md={1}>
                    <Checkbox
                      data-testid={`checkbox-${featureFlag.name}`}
                      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                      onChange={() => updateFeatureFlag(featureFlag.name, !featureFlag.enabled)}
                      checked={featureFlag.enabled}
                    />
                  </Grid>
                  <Grid item md={11}>
                    <Typography fontFamily="DobraSlabMedium">{featureFlag.label}</Typography>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Accordion>
        </Paper>
      </Drawer>
    </Box>
  );
};

export default ToolkitPanel;
