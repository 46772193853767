import React from 'react';
import { Box, Typography } from '@coolblue-development/becky';
import WarningIcon from '../../../Icons/WarningIcon';
import CloseIcon from '../../../Icons/CloseIcon';

interface ValidationMessageProps {
  onClose: () => void;
}

const ValidationMessage: React.FC<ValidationMessageProps> = ({ onClose }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        background: '#FEF1E8',
        padding: '7px',
        marginTop: '10px',
        borderRadius: '4px',
        justifyContent: 'space-between'
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <WarningIcon />
        <Typography sx={{ marginLeft: '7px' }}>The selected image is bigger than 5mb</Typography>
      </Box>
      <CloseIcon color="#E50000" onClick={onClose} />
    </Box>
  );
};

export default ValidationMessage;
