import { Country } from '../models/PhoneCountryCodes';

export const countrySelectorUtil = (countries: Country[]): Country => {
  let defaultCountry = countries.find(country => country.code === '+31')!;

  connect.agent(agent => {
    const username = agent.getConfiguration().username;
    const country = username.slice(-2);

    if (country === 'be') {
      defaultCountry = countries.find(country => country.code === '+32')!;
    } else if (country === 'de') {
      defaultCountry = countries.find(country => country.code === '+49')!;
    }
  });

  return defaultCountry;
};
