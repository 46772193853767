/*  This method provides us with the active current voice contact. As mentioned in the streams api docs
it is good practice not to persist the voice contact object as internal state. This is why we always want to make sure the contact
still exists by fetching it first from the agent API. Leaving this step out can result in some edge case errors resulting in components unable to render */

export const getVoiceContact = (voiceContactId: string | undefined): connect.Contact | undefined => {
  if (!voiceContactId) return undefined;

  let voiceContact: connect.Contact | undefined = undefined;

  connect.agent(agent => {
    const contacts = agent.getContacts(voiceContact?.getType());
    voiceContact = contacts.find(contact => contact.getContactId() === voiceContactId);
  });

  return voiceContact;
};
