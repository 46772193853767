import React from 'react';
import { Box, IconButton } from '@coolblue-development/becky';
import ItalicButton from './ItalicButton';
import BoldButton from './BoldButton';
import LinkButton from './LinkButton';
import { useAppContext } from '../../../../../AppContext';

type FormatFunction = (text: string) => string;

const ChatActionInput = () => {
  const { setAgentMessage, selectedChat } = useAppContext();

  const addRichText = (format: FormatFunction) => () => {
    setAgentMessage(prevAgentMessages => {
      const formattedMessages = prevAgentMessages.map(msg =>
        msg.selectedChat === selectedChat ? { ...msg, message: format(msg.message) } : msg
      );

      return formattedMessages;
    });
  };

  const buttonConfigs = [
    { format: (text: string) => `**${text}**`, component: BoldButton },
    { format: (text: string) => `_${text}_`, component: ItalicButton },
    { format: (text: string) => `[${text}](https://coolblue.nl)`, component: LinkButton }
  ];

  return (
    <Box
      sx={{
        border: '#DDDDDD 1px solid',
        borderRadius: '4px 4px 0px 0px',
        paddingLeft: '8px'
      }}
    >
      {buttonConfigs.map(({ format, component: ButtonComponent }, index) => (
        <IconButton
          key={index}
          preset="text"
          onClick={addRichText(format)}
          iconSize="small"
          sx={{
            width: 'auto',
            height: 'auto',
            padding: '8px',
            margin: '8px 0px',
            ':hover': { background: '#F3F3F3' }
          }}
        >
          <ButtonComponent />
        </IconButton>
      ))}
    </Box>
  );
};

export default ChatActionInput;
