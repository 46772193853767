import React from 'react';
import { Box, IconButton } from '@coolblue-development/becky';
import NumpadIcon from '../../../Icons/NumpadIcon';
import RecentIcon from '../../../Icons/RecentIcon';
import { useAppContext } from '../../../../../AppContext';
import { TelephonyWidgetContainer } from '../../../../../models/TelephonyWidgetContainer';

const Navigation = () => {
  const { setActiveTelephonyWidgetContainer, activeTelephonyWidgetContainer, voiceContact } = useAppContext();
  const isOutboundContainerActive =
    activeTelephonyWidgetContainer === TelephonyWidgetContainer.OUTBOUND ||
    activeTelephonyWidgetContainer === TelephonyWidgetContainer.CALL;
  const isRecentCallContainerActive = activeTelephonyWidgetContainer === TelephonyWidgetContainer.RECENT;

  const iconButtonStyle = {
    boxShadow: 'none',
    width: '36px',
    height: '32px',
    padding: '9px',
    ':hover': { background: '#33373F', boxShadow: 'none' }
  };

  const handleButtonClick = (container: TelephonyWidgetContainer) => {
    setActiveTelephonyWidgetContainer(container);
  };

  return (
    <Box
      sx={{
        border: '1px solid #868A90',
        borderRadius: '4px',
        margin: '0 auto',
        display: 'flex',
        padding: '4px',
        gap: '4px',
        marginBottom: '24px'
      }}
    >
      <IconButton
        data-testid="outbound-button"
        size="small"
        sx={{
          ...iconButtonStyle,
          background: isOutboundContainerActive ? '#33373F' : 'none',
          svg: { fill: isOutboundContainerActive ? '#fff' : '#868A90' }
        }}
        onClick={() =>
          handleButtonClick(voiceContact ? TelephonyWidgetContainer.CALL : TelephonyWidgetContainer.OUTBOUND)
        }
      >
        <NumpadIcon />
      </IconButton>
      <IconButton
        data-testid="recent-button"
        size="small"
        sx={{
          ...iconButtonStyle,
          background: isRecentCallContainerActive ? '#33373F' : 'none',
          svg: { fill: isRecentCallContainerActive ? '#fff' : '#868A90' }
        }}
        onClick={() => handleButtonClick(TelephonyWidgetContainer.RECENT)}
      >
        <RecentIcon />
      </IconButton>
    </Box>
  );
};

export default Navigation;
