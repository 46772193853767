import { Box, Button, Typography } from '@coolblue-development/becky';
import TelephoneIcon from '../../../Icons/TelephoneIcon';
import CheckmarkIcon from '../../../Icons/CheckMarkIcon';
import { useAppContext } from '../../../../../AppContext';

interface TelephonyTopPanelProps {
  selectedRecentCall: {
    phoneNumber: string;
    index: number;
  };
}

const TelephonyTopPanel: React.FC<TelephonyTopPanelProps> = ({ selectedRecentCall }) => {
  const { voiceContact } = useAppContext();
  const { phoneNumber, index } = selectedRecentCall;
  const voiceContactState = voiceContact?.getState().type;

  return (
    <Box
      sx={{
        height: '52px',
        borderBottom: '#DDDDDD 1px solid',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px',
        width: '100%',
        paddingRight: '15px'
      }}
    >
      {phoneNumber && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                background: '#0090E3',
                borderRadius: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '30px',
                height: '30px',
                marginRight: '10px'
              }}
            >
              <TelephoneIcon />
            </Box>
            <Typography variant="h3">{phoneNumber}</Typography>
          </Box>
          <Box>
            <Button
              size="small"
              preset="callToAction"
              disabled={
                voiceContactState !== connect.ContactStateType.CONNECTING &&
                voiceContactState !== connect.ContactStateType.INCOMING &&
                voiceContactState !== connect.ContactStateType.CONNECTED &&
                voiceContact &&
                index === 0
                  ? false
                  : true
              }
              onClick={() =>
                voiceContact?.clear({
                  success: function () {},
                  failure: function () {}
                })
              }
            >
              <CheckmarkIcon />
              <Typography variant="h5" sx={{ marginLeft: '7px' }}>
                Close contact
              </Typography>
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default TelephonyTopPanel;
