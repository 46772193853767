import CassieContainer from './CassieContainer';
import CassieLegacyContainer from './CassieLegacyContainer';
import { CassieWebsocketContextProvider } from './websocket/CassieWebsocketContext';
import Contact from '../../models/Contact';
import { FeatureFlag } from '../constants';
import { useFeatureFlags } from '../ToolkitPanel/useFeatureFlags';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../AppContext';
import { getVoiceContact } from '../../utils/getVoiceContact';

interface CassieInjectorProps {
  contact: Contact | undefined;
  cassieInstanceURL: string;
}

const CassieInjector: React.FC<CassieInjectorProps> = ({ contact, cassieInstanceURL }) => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { voiceContact } = useAppContext();
  const isCassieStandaloneAppEnabled = isFeatureFlagEnabled(FeatureFlag.CASSIE_STANDALONE_APP);
  const [routingGroup, setRoutingGroup] = useState<string>('default'); // Default value can not be an empty string

  useEffect(() => {
    const contact = getVoiceContact(voiceContact?.getContactId());

    if (!contact?.getQueue()) {
      return;
    }

    setRoutingGroup(contact?.getQueue().name);
  }, [voiceContact]);

  return isCassieStandaloneAppEnabled ? (
    <CassieWebsocketContextProvider>
      <CassieContainer contact={contact} routingGroup={routingGroup} />
    </CassieWebsocketContextProvider>
  ) : (
    <CassieLegacyContainer cassieInstanceURL={cassieInstanceURL} contact={contact} routingGroup={routingGroup} />
  );
};

export default CassieInjector;
