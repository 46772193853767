import React, { useEffect, useState } from 'react';
import { IsB2bAgent } from '../../../../../utils/isB2bAgent';
import FlagIcon from '../../../Icons/FlagIcon';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@coolblue-development/becky';
import CheckmarkIcon from '../../../Icons/CheckMarkIcon';
import 'amazon-connect-streams';
import { countrySelectorUtil } from '../../../../../utils/countrySelectorUtil';
import { phoneCountryCodes, Country } from '../../../../../models/PhoneCountryCodes';

interface CountrySelectorProps {
  onCountrySelect: (country: Country) => void;
}

const CountrySelector: React.FC<CountrySelectorProps> = ({ onCountrySelect }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (country: Country) => {
    setSelectedCountry(country);
    onCountrySelect(country);
    handleClose();
  };

  useEffect(() => {
    const defaultCountry = countrySelectorUtil(phoneCountryCodes);
    setSelectedCountry(defaultCountry);
  }, []);

  const filteredPhoneCountryCodes = IsB2bAgent()
    ? phoneCountryCodes
    : phoneCountryCodes.filter(country => !country.isB2b);

  return (
    <>
      <Button
        sx={{
          background: 'none',
          boxShadow: 'none',
          fontSize: '',
          alignSelf: 'center',
          height: '45px',
          padding: '7px',
          ':hover': { background: '#33373F', boxShadow: 'none' }
        }}
        data-testid="country-selector-button"
        size="large"
        onClick={handleClick}
        startIcon={selectedCountry ? <FlagIcon Component={selectedCountry.icon} /> : null}
      >
        {selectedCountry?.code}
      </Button>
      <Menu
        sx={{ '& .MuiPaper-root': { background: '#33373F', color: '#fff' } }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {filteredPhoneCountryCodes.map(country => (
          <MenuItem
            key={country.code}
            sx={{ ':hover': { background: '#1C2027' } }}
            onClick={() => handleMenuItemClick(country)}
          >
            <ListItemIcon>
              <FlagIcon Component={country.icon} />
            </ListItemIcon>
            <ListItemText>{country.code}</ListItemText>
            {selectedCountry?.code === country.code && (
              <ListItemIcon sx={{ svg: { maxWidth: '10px', marginLeft: 'auto' } }}>
                <CheckmarkIcon />
              </ListItemIcon>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CountrySelector;
