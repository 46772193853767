import { Box, Typography } from '@coolblue-development/becky';
import React from 'react';
import { IsB2bAgent } from '../../../utils/isB2bAgent';

interface UsernameProps {
  name: string;
}

const UserDetails: React.FC<UsernameProps> = ({ name }) => {
  return (
    <Box>
      <Typography variant="h5" sx={{ textAlign: 'right' }}>
        {name}
      </Typography>
      <Typography variant="body1">{!IsB2bAgent() ? 'Customer Service' : 'Business Journeys'}</Typography>
    </Box>
  );
};

export default UserDetails;
