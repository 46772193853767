import { Box } from '@coolblue-development/becky';
import React from 'react';
import { SpeechBubbleProps } from '../../../../models/SpeechBubbleProps';

const SpeechBubble: React.FC<SpeechBubbleProps> = ({ message, role, timestamp, attachment }) => {
  const isAgent = role !== 'CUSTOMER';
  const bubbleStyle = {
    width: 'fit-content',
    background: isAgent ? '#CCE9F9' : '#FFF',
    borderRadius: '5px',
    padding: '15px',
    marginLeft: isAgent ? 'auto' : '',
    maxWidth: '60%'
  };

  const localTime = new Date(timestamp).toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit'
  });

  const formatMessage = (text: string) => {
    text = text.replace(/\*\*([^*]+)\*\*/g, '<strong>$1</strong>');
    text = text.replace(/_([^_]+)_/g, '<em>$1</em>');
    text = text.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" target="_blank">$1</a>');

    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  };

  const returnImage = (attachment: Blob) => {
    return <img src={URL.createObjectURL(attachment)} style={{ width: '100%', borderRadius: '5px' }} />;
  };

  return message || attachment ? (
    <Box sx={{ marginBottom: '10px', textAlign: isAgent ? 'right' : 'left' }} data-testid="speech-bubble">
      <Box style={bubbleStyle} data-testid={isAgent ? 'agent-bubble' : 'customer-bubble'}>
        {message && formatMessage(message)}
        {attachment && returnImage(attachment)}
      </Box>
      {localTime}
    </Box>
  ) : null;
};

export default SpeechBubble;
