import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

type LocalStorageState<T> = [T, Dispatch<SetStateAction<T>>];

function useLocalStorageState<T>(key: string, defaultValue: T): LocalStorageState<T> {
  const [value, setValue] = useState<T>(() => {
    try {
      const storedValue = window.localStorage.getItem(key);
      return storedValue ? (JSON.parse(storedValue) as T) : defaultValue;
    } catch (e) {
      console.warn('Error parsing local storage value, using default value', e);
      return defaultValue;
    }
  });

  const handleStorageChange = useCallback(() => {
    const newValue = localStorage.getItem(key);
    if (value !== newValue) {
      try {
        setValue(newValue ? (JSON.parse(newValue) as T) : defaultValue);
      } catch (e) {
        console.warn('Error parsing local storage value, using default value', e);
        setValue(defaultValue);
      }
    }
  }, [key, value, defaultValue]);

  useEffect(() => {
    // Listen for storage events
    window.addEventListener('storage', handleStorageChange);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [handleStorageChange]);

  // Update local storage when value changes
  useEffect(() => {
    const currentValue = window.localStorage.getItem(key);
    if (currentValue !== JSON.stringify(value)) {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
}

export { useLocalStorageState };
