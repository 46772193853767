import './CassieLegacyContainer.css';

import { ReactElement, useEffect, useRef, useState } from 'react';

import Contact from '../../models/Contact';
import ToggleWidthButton from './ToggleWidthButton';

interface CassieContainerPropsInterface {
  cassieInstanceURL: string;
  contact: Contact | undefined;
  routingGroup: string;
}

function CassieLegacyContainer({
  cassieInstanceURL,
  contact,
  routingGroup
}: CassieContainerPropsInterface): ReactElement {
  const containerRef = useRef<HTMLIFrameElement>(null);
  const [widthIndex, setWidthIndex] = useState(0);
  const widthOptions = [350, 700, 1200];

  useEffect(() => {
    if (containerRef.current === null) return;

    if (contact === undefined) {
      containerRef.current.contentWindow?.postMessage({ eventType: 'RESET_CONVERSATION' }, '*');
      return;
    }

    const hasPhoneNumber = !!contact.phoneNumber;

    const payload = {
      channel: 'cato',
      channelConversationId: contact.contactId,
      ...(hasPhoneNumber && { lookupCustomer: { fieldType: 'telephone', fieldValue: contact.phoneNumber } }),
      ...(hasPhoneNumber && { visualIdentifier: contact.phoneNumber }),
      integrationInferredSubsidiary: contact.groupSubsidiary,
      routingGroup: routingGroup
    };

    containerRef.current.contentWindow?.postMessage({ eventType: 'OPEN_CONVERSATION', payload }, '*');
  }, [contact]);

  const toggleWidthOption = (): void => {
    setWidthIndex((widthIndex + 1) % widthOptions.length);
  };

  return (
    <div className="cassie-container" style={{ width: widthOptions[widthIndex] }}>
      <iframe
        className="cassie-frame"
        ref={containerRef}
        src={cassieInstanceURL}
        title="Contact Assistant"
        allow="clipboard-write"
      />
      <ToggleWidthButton onClick={toggleWidthOption} widthIndex={widthIndex} widthOptions={widthOptions} />
    </div>
  );
}

export default CassieLegacyContainer;
