import { AppBar, Avatar } from '@coolblue-development/becky';
import ProfileMenu from './ProfileMenu';
import CatoLogo from './CatoLogo';
import StatusMenu from './StatusMenu';
import Username from './UserDetails';
import { useAppContext } from '../../../AppContext';
export interface HeaderProps {
  instanceUrl: string;
}

const Header: React.FC<HeaderProps> = ({ instanceUrl }) => {
  const { agent } = useAppContext();

  return (
    <AppBar
      sx={{ zIndex: 2, position: 'relative' }}
      elevation={0}
      variant="outlined"
      position="static"
      logo={<CatoLogo />}
      title="Cato"
      actions={
        <>
          <StatusMenu />
          <Username name={agent?.getName() ?? ''} />
        </>
      }
      avatar={
        <Avatar>
          <ProfileMenu instanceUrl={instanceUrl} />
        </Avatar>
      }
    />
  );
};

export default Header;
