import React, { createContext, useContext, useEffect, useState } from 'react';
import syncFeatureFlags, { FeatureFlagInterface, featureFlagList } from './components/constants';

import { Page } from './models/Page';
import { TelephonyWidgetContainer } from './models/TelephonyWidgetContainer';
import { useLocalStorageState } from './utils/hooks/useLocalStorageState';

export type chatMessage = {
  Content?: string;
  ParticipantRole?: 'AGENT' | 'CUSTOMER' | 'SYSTEM' | undefined;
  AbsoluteTime: string;
  Id: string;
  ContactId?: string;
  attachment?: Blob;
};

export type ContactTypingInfo = {
  contactId: string;
  isTyping: boolean;
};

type AgentMessage = {
  selectedChat: string | null;
  message: string;
};

export type DefaultCallActionState = { customer: boolean; thirdParty: boolean };
export const defaultCallActionStateValue = { customer: false, thirdParty: false };

export const AppContext = createContext<{
  agent: connect.Agent | undefined;
  setAgent: React.Dispatch<React.SetStateAction<connect.Agent | undefined>>;
  activePage: Page;
  setActivePage: React.Dispatch<React.SetStateAction<Page>>;
  chatMessages: chatMessage[];
  setChatMessages: React.Dispatch<React.SetStateAction<chatMessage[]>>;
  agentEmail: string | undefined;
  agentMessage: AgentMessage[];
  setAgentMessage: React.Dispatch<React.SetStateAction<AgentMessage[]>>;
  contacts: connect.Contact[];
  setContacts: React.Dispatch<React.SetStateAction<connect.Contact[]>>;
  connectedContacts: connect.Contact[];
  setConnectedContacts: React.Dispatch<React.SetStateAction<connect.Contact[]>>;
  loader: boolean;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  agentChatSession: connect.AgentChatSession[];
  setAgentChatSession: React.Dispatch<React.SetStateAction<connect.AgentChatSession[]>>;
  agentVoiceConnection: connect.BaseConnection | null;
  setAgentVoiceConnection: React.Dispatch<React.SetStateAction<connect.BaseConnection | null>>;
  selectedChat: string | null;
  setSelectedChat: React.Dispatch<React.SetStateAction<string | null>>;
  isTyping: ContactTypingInfo[];
  setIsTyping: React.Dispatch<React.SetStateAction<ContactTypingInfo[]>>;
  isTelephonyWidgetOpen: boolean;
  setIsTelephonyWidgetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  featureFlags: FeatureFlagInterface[] | [];
  setFeatureFlags: React.Dispatch<React.SetStateAction<FeatureFlagInterface[]>>;
  telephonyStatusBarOpen: boolean;
  setTelephonyStatusBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activeTelephonyWidgetContainer: TelephonyWidgetContainer;
  setActiveTelephonyWidgetContainer: React.Dispatch<React.SetStateAction<TelephonyWidgetContainer>>;
  thirdPartyVoiceConnection: connect.VoiceConnection | null;
  setThirdPartyVoiceConnection: React.Dispatch<React.SetStateAction<connect.VoiceConnection | null>>;
  voiceContact: connect.Contact | null;
  setVoiceContact: React.Dispatch<React.SetStateAction<connect.Contact | null>>;
  agentState: connect.AgentState | null;
  setAgentState: React.Dispatch<React.SetStateAction<connect.AgentState | null>>;
  isAgentMute: boolean;
  setIsAgentMute: React.Dispatch<React.SetStateAction<boolean>>;
  isAgentHold: DefaultCallActionState;
  setIsAgentHold: React.Dispatch<React.SetStateAction<DefaultCallActionState>>;
}>({
  agent: undefined,
  setAgent: () => {},
  activePage: Page.TELEPHONY,
  setActivePage: () => {},
  chatMessages: [],
  setChatMessages: () => {},
  agentEmail: undefined,
  agentMessage: [],
  setAgentMessage: () => {},
  contacts: [],
  setContacts: () => {},
  connectedContacts: [],
  setConnectedContacts: () => {},
  loader: false,
  setLoader: () => {},
  agentChatSession: [],
  setAgentChatSession: () => {},
  agentVoiceConnection: null,
  setAgentVoiceConnection: () => {},
  selectedChat: '',
  setSelectedChat: () => {},
  isTyping: [],
  setIsTyping: () => {},
  isTelephonyWidgetOpen: false,
  setIsTelephonyWidgetOpen: () => {},
  featureFlags: [],
  setFeatureFlags: () => {},
  telephonyStatusBarOpen: false,
  setTelephonyStatusBarOpen: () => {},
  activeTelephonyWidgetContainer: TelephonyWidgetContainer.OUTBOUND,
  setActiveTelephonyWidgetContainer: () => {},
  thirdPartyVoiceConnection: null,
  setThirdPartyVoiceConnection: () => {},
  voiceContact: null,
  setVoiceContact: () => {},
  agentState: null,
  setAgentState: () => {},
  isAgentMute: false,
  setIsAgentMute: () => {},
  isAgentHold: defaultCallActionStateValue,
  setIsAgentHold: () => {}
});

export const useAppContext = () => useContext(AppContext);

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [agent, setAgent] = useState<connect.Agent>();
  const [activePage, setActivePage] = useState<Page>(Page.TELEPHONY);
  const [chatMessages, setChatMessages] = useState<chatMessage[]>([]);
  const [agentMessage, setAgentMessage] = useState<AgentMessage[]>([]);
  const [contacts, setContacts] = useState<connect.Contact[]>([]);
  const [connectedContacts, setConnectedContacts] = useState<connect.Contact[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [agentChatSession, setAgentChatSession] = useState<connect.AgentChatSession[]>([]);
  const [agentVoiceConnection, setAgentVoiceConnection] = useState<connect.BaseConnection | null>(null);
  const [selectedChat, setSelectedChat] = useState<string | null>(contacts[0]?.getContactId);
  const [isTyping, setIsTyping] = useState<ContactTypingInfo[]>([]);
  const [isTelephonyWidgetOpen, setIsTelephonyWidgetOpen] = useState<boolean>(false);
  const [agentEmail, setAgentEmail] = useState<string>();
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagInterface[] | []>([]);
  const [telephonyStatusBarOpen, setTelephonyStatusBarOpen] = useState<boolean>(false);
  const [activeTelephonyWidgetContainer, setActiveTelephonyWidgetContainer] = useState<TelephonyWidgetContainer>(
    TelephonyWidgetContainer.OUTBOUND
  );
  const [thirdPartyVoiceConnection, setThirdPartyVoiceConnection] = useState<connect.VoiceConnection | null>(null);
  const [voiceContact, setVoiceContact] = useState<connect.Contact | null>(null);
  const [agentState, setAgentState] = useState<connect.AgentState | null>(null);
  const [isAgentMute, setIsAgentMute] = useState<boolean>(false);
  const [isAgentHold, setIsAgentHold] = useState<DefaultCallActionState>(defaultCallActionStateValue);
  // Get the agent's email for Cassie's websocket connection
  useEffect(() => {
    connect.agent(function (agent) {
      const email = agent.getConfiguration().username;
      setAgentEmail(email);
    });
  }, []);

  // Load feature flags from local storage
  const [localFeatureFlags, setLocalFeatureFlags] = useLocalStorageState<FeatureFlagInterface[]>(
    'featureFlags',
    featureFlagList
  );
  useEffect(() => {
    setFeatureFlags(localFeatureFlags);
  }, [localFeatureFlags]);

  // Sync the user's feature flags with the up-to-date hardcoded list
  useEffect(() => {
    const synchronisedFeatureFlags = syncFeatureFlags(localFeatureFlags);
    if (JSON.stringify(localFeatureFlags) !== JSON.stringify(synchronisedFeatureFlags)) {
      setLocalFeatureFlags(synchronisedFeatureFlags);
    }
  }, [localFeatureFlags, setLocalFeatureFlags]);

  return (
    <AppContext.Provider
      value={{
        agent,
        setAgent,
        activePage,
        setActivePage,
        chatMessages,
        setChatMessages,
        agentEmail,
        agentMessage,
        setAgentMessage,
        contacts,
        setContacts,
        connectedContacts,
        setConnectedContacts,
        loader,
        setLoader,
        agentChatSession,
        setAgentChatSession,
        agentVoiceConnection,
        setAgentVoiceConnection,
        selectedChat,
        setSelectedChat,
        isTyping,
        setIsTyping,
        isTelephonyWidgetOpen,
        setIsTelephonyWidgetOpen,
        telephonyStatusBarOpen,
        setTelephonyStatusBarOpen,
        featureFlags,
        activeTelephonyWidgetContainer,
        setActiveTelephonyWidgetContainer,
        thirdPartyVoiceConnection,
        setThirdPartyVoiceConnection,
        voiceContact,
        setVoiceContact,
        agentState,
        setAgentState,
        isAgentMute,
        setIsAgentMute,
        isAgentHold,
        setIsAgentHold,
        setFeatureFlags: setLocalFeatureFlags
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
