import { Box } from '@mui/system';
import React from 'react';
import { useAppContext } from '../../../AppContext';
import { Page } from '../../../models/Page';

interface CcpPanelProps {
  containerRef: React.RefObject<HTMLDivElement>;
}

const CcpPanel: React.FC<CcpPanelProps> = ({ containerRef }) => {
  const { activePage, telephonyStatusBarOpen } = useAppContext();

  return (
    <Box
      ref={containerRef}
      hidden={activePage !== Page.CCP}
      sx={{ height: `calc(100% - ${telephonyStatusBarOpen ? '169px' : '115px'})` }}
    ></Box>
  );
};

export default CcpPanel;
