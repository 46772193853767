import { Box } from '@coolblue-development/becky';
import ChatActionInput from './ChatActionInput';
import ChatInputForm from './ChatInputForm';

const ChatInput = () => {
  return (
    <Box
      sx={{
        background: '#FFF',
        width: '100%',
        padding: '15px',
        borderTop: '#DDDDDD 1px solid'
      }}
    >
      <ChatActionInput />
      <ChatInputForm />
    </Box>
  );
};

export default ChatInput;
