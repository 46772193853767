import { Page } from '../../../models/Page';
import { useAppContext } from '../../../AppContext';
import { Box, List, ListItem, ListItemButton, ListItemText, Typography } from '@coolblue-development/becky';

const Other = () => {
  const menuItems: Page[] = [Page.SETTINGS];
  const { setActivePage, activePage } = useAppContext();

  const handleMenuItemClick = (item: Page) => {
    setActivePage(item);
  };

  return (
    <>
      <Typography variant="h5" component="h1" sx={{ marginLeft: '25px' }}>
        Other
      </Typography>
      <List>
        {menuItems.map(item => (
          <ListItem key={item} disablePadding>
            <ListItemButton disableGutters onClick={() => handleMenuItemClick(item)}>
              {activePage === item && (
                <Box
                  sx={{ position: 'absolute', left: '25px', background: '#0090E3', width: '3px', height: '100%' }}
                ></Box>
              )}
              <ListItemText sx={{ marginLeft: '40px' }}>
                <Typography sx={{ lineHeight: 1.25 }} variant={activePage === item ? 'h5' : 'body1'}>
                  {item}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default Other;
