import { Box } from '@coolblue-development/becky';
import ContactPhoneNumber from './ContactPhoneNumber';
import Timer from './Timer';

const ContactContainer = () => {
  return (
    <Box>
      <Timer />
      <ContactPhoneNumber />
    </Box>
  );
};

export default ContactContainer;
