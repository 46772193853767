import { Box } from '@coolblue-development/becky';

const SpeechBubbleLoader = () => {
  return (
    <Box
      data-testid={'speechBubbleLoader'}
      sx={{ display: 'flex', gap: '5px', width: '70px', background: 'white', padding: '15px', borderRadius: '5px' }}
    >
      <Box className="message-loader-dot"></Box>
      <Box className="message-loader-dot"></Box>
      <Box className="message-loader-dot"></Box>
    </Box>
  );
};

export default SpeechBubbleLoader;
