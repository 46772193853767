import React, { useEffect, useState } from 'react';
import { Label, Typography } from '@coolblue-development/becky';
import GlasshourIcon from '../../../Icons/GlasshourIcon';
import { formatDuration } from '../../../../../utils/formatDuration';

interface LastChatTimerProps {
  contactId: string;
}

const LastChatTimer: React.FC<LastChatTimerProps> = (props: LastChatTimerProps) => {
  const lastMessageData = JSON.parse(localStorage.getItem(`lastChat_${props.contactId}`) || '{}');
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (Object.keys(lastMessageData).length > 0) {
      const lastChatTime = new Date(lastMessageData.AbsoluteTime).getTime();
      const currentTime = new Date().getTime();
      const timeDifference = Math.floor(currentTime - lastChatTime);
      setDuration(timeDifference);
    }

    const timerId = setInterval(() => setDuration(prevDuration => prevDuration + 1000), 1000);
    return () => clearInterval(timerId);
  }, [lastMessageData]);

  return (
    <Label color="blue" variant="subtle" sx={{ marginLeft: '5px' }}>
      <GlasshourIcon />
      <Typography variant="body1" sx={{ marginLeft: '5px' }}>
        {formatDuration(duration)}
      </Typography>
    </Label>
  );
};

export default LastChatTimer;
