import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import { useAppContext } from '../../../../AppContext';
import SpeechBubble from './SpeechBubble';
import SpeechBubbleLoader from './SpeechBubbleLoader';
import ScrollToBottom from './ScrollToBottom';
import NewMessageNotification from './NewMessageNotification';
import { FeatureFlag } from '../../../constants';
import { useFeatureFlags } from '../../../ToolkitPanel/useFeatureFlags';

const ChatBody = () => {
  const { chatMessages, selectedChat, isTyping } = useAppContext();
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [displayNewMessagesNotifcation, setDisplayNewMessagesNotifcation] = useState(false);
  const chatBodyRef = useRef<HTMLDivElement | null>(null);
  const [sliceAtIndex, setSliceAtIndex] = useState(0);
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const IsNewMessageNotificationEnabled = isFeatureFlagEnabled(FeatureFlag.NEW_MESSAGE_NOTIFICATION);

  const selectedChatMessages = useMemo(() => {
    return chatMessages.filter(message => message.ContactId === selectedChat);
  }, [chatMessages, selectedChat]);

  useEffect(() => {
    if (!isScrolledToBottom) {
      setUnreadMessageCount(prevCount => prevCount + 1);

      if (!displayNewMessagesNotifcation) {
        setSliceAtIndex(selectedChatMessages.length - 1);
        setDisplayNewMessagesNotifcation(true);
      }
    }
  }, [selectedChatMessages]);

  useEffect(() => {
    if (isScrolledToBottom) {
      setUnreadMessageCount(0);
    }
  }, [isScrolledToBottom]);

  useEffect(() => {
    setDisplayNewMessagesNotifcation(false);
    setUnreadMessageCount(0);
    handleScrollToBottom();
  }, [selectedChat]);

  useEffect(() => {
    const chatBodyElement = chatBodyRef.current;
    if (chatBodyElement) {
      const handleScroll = () => {
        const isAtBottom =
          Math.abs(chatBodyElement.scrollHeight - chatBodyElement.scrollTop - chatBodyElement.clientHeight) < 100;
        setIsScrolledToBottom(isAtBottom);
      };

      handleScroll();
      chatBodyElement.addEventListener('scroll', handleScroll);
      return () => chatBodyElement?.removeEventListener('scroll', handleScroll);
    }
  }, [selectedChatMessages]);

  useEffect(() => {
    if (chatBodyRef.current && isScrolledToBottom) {
      handleScrollToBottom();
    }
  }, [selectedChatMessages]);

  const handleScrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
    setIsScrolledToBottom(true);
  };

  return (
    <Box
      sx={{
        flex: 2,
        background: '#F2F7FC',
        overflow: 'auto',
        position: 'relative'
      }}
      data-testid="chat-body"
      ref={chatBodyRef}
    >
      <div style={{ overflow: 'auto', padding: '10px' }}>
        {IsNewMessageNotificationEnabled ? (
          <>
            {selectedChatMessages.slice(0, sliceAtIndex).map(message => (
              <SpeechBubble
                message={message?.Content || ''}
                timestamp={message?.AbsoluteTime}
                role={message?.ParticipantRole}
                attachment={message?.attachment}
                key={message.Id}
              />
            ))}

            {displayNewMessagesNotifcation && <NewMessageNotification />}

            {selectedChatMessages.slice(sliceAtIndex).map(message => (
              <SpeechBubble
                message={message?.Content || ''}
                timestamp={message?.AbsoluteTime}
                role={message?.ParticipantRole}
                attachment={message?.attachment}
                key={message.Id}
              />
            ))}
          </>
        ) : (
          selectedChatMessages.map(message => (
            <SpeechBubble
              message={message?.Content || ''}
              timestamp={message?.AbsoluteTime}
              role={message?.ParticipantRole}
              attachment={message?.attachment}
              key={message.Id}
            />
          ))
        )}

        {isTyping.map(
          typingEvent =>
            typingEvent.contactId === selectedChat &&
            typingEvent.isTyping && <SpeechBubbleLoader key={typingEvent.contactId} />
        )}
      </div>

      {!isScrolledToBottom && (
        <ScrollToBottom handleSrollToBottom={handleScrollToBottom} unreadMessageCount={unreadMessageCount} />
      )}
    </Box>
  );
};

export default ChatBody;
