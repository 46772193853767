import React from 'react';
import { Box, IconButton, Typography } from '@coolblue-development/becky';

interface CallActionButtonProps {
  icon: React.ReactNode;
  label: string;
  dataTestId: string;
  onClick: () => void;
  disabled: boolean;
  backgroundColor: string;
  hoverColor: string;
  svgFill: string;
  disabledBackgroundColor?: string;
}

const CallActionButton: React.FC<CallActionButtonProps> = ({
  icon,
  label,
  dataTestId,
  onClick,
  disabled,
  backgroundColor,
  hoverColor,
  svgFill,
  disabledBackgroundColor
}) => (
  <Box>
    <IconButton
      data-testid={dataTestId}
      disabled={disabled}
      onClick={onClick}
      sx={{
        width: '52px',
        height: '52px',
        borderRadius: '30px',
        boxShadow: 'none',
        background: backgroundColor,
        svg: {
          fill: svgFill ? svgFill : '#fff',
          width: 'auto !important',
          height: 'auto !important'
        },
        '&:hover': { background: hoverColor, boxShadow: 'none' },
        '&:disabled': { background: disabledBackgroundColor ? '#868A90' : 'none' }
      }}
    >
      {icon}
    </IconButton>
    <Typography sx={{ color: '#868A90' }}> {label || '\u00A0'}</Typography>
  </Box>
);

export default CallActionButton;
