import { Avatar, Box, Grid, IconButton, Typography } from '@coolblue-development/becky';
import React, { useEffect, useState } from 'react';
import TelephoneIcon from '../../../Icons/TelephoneIcon';
import { useAppContext } from '../../../../../AppContext';
import QuickConnectsHeader from './QuickConnectsHeader';
import { TelephonyWidgetContainer } from '../../../../../models/TelephonyWidgetContainer';
import QuickConnectsSearch from './QuickConnectsSearch';
import { DEPARTMENTS } from '../../../../../utils/constants';
import { IsB2bAgent } from '../../../../../utils/isB2bAgent';

export type QuickConnectsType = connect.EndpointType | typeof DEPARTMENTS;

const QuickConnectsContainer = () => {
  const { voiceContact, setActiveTelephonyWidgetContainer } = useAppContext();
  const [quickConnects, setQuickConnects] = useState<connect.Endpoint[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState<QuickConnectsType>(DEPARTMENTS);

  useEffect(() => {
    connect.agent(agent => {
      const allQueues = agent.getAllQueueARNs();
      const defaultOutboundQueue = agent.getRoutingProfile().defaultOutboundQueue.queueARN;

      const queues = voiceContact ? [...allQueues, defaultOutboundQueue] : [defaultOutboundQueue];

      agent.getEndpoints(queues, {
        success: data => {
          setQuickConnects(data.endpoints);
        },
        failure: () => {}
      });
    });
  }, [voiceContact]);

  const filteredQuickConnects = quickConnects.filter(
    quickConnect =>
      quickConnect.name.toLowerCase().includes(query.toLowerCase()) &&
      (filter === DEPARTMENTS
        ? [connect.EndpointType.QUEUE, connect.EndpointType.PHONE_NUMBER].includes(quickConnect.type)
        : quickConnect.type === filter)
  );

  const quickConnectsToDisplay = IsB2bAgent() ? filteredQuickConnects : quickConnects;

  const callQuickConnect = (selectedIndex: number) => {
    connect.agent(agent => {
      const endpoint = connect.Endpoint.byPhoneNumber(quickConnectsToDisplay[selectedIndex].phoneNumber);
      agent.connect(endpoint);

      if (voiceContact) {
        voiceContact.addConnection(quickConnectsToDisplay[selectedIndex]);
      }

      setActiveTelephonyWidgetContainer(TelephonyWidgetContainer.CALL);
    });
  };

  return (
    <>
      <QuickConnectsHeader filter={filter} setFilter={setFilter} />
      {IsB2bAgent() && <QuickConnectsSearch query={query} setQuery={setQuery} />}
      <Box
        sx={{
          color: 'white',
          scrollbarWidth: 'none',
          overflow: 'auto',
          marginBottom: '-24px'
        }}
      >
        {quickConnectsToDisplay.map((quickConnect, index) => (
          <Grid
            key={index}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            container
            sx={{
              alignItems: 'center',
              borderTop: '1px solid #262930',
              textAlign: 'left',
              padding: '12px',
              ':hover': { background: '#262930', borderRadius: '4px' },
              transition: 'background 0.3s ease'
            }}
          >
            <Grid item xs={2}>
              <Avatar
                sizes="small"
                sx={{
                  background: '#0090E3',
                  color: '#fff',
                  height: '32px',
                  width: '32px',
                  fontSize: '1.4rem !important'
                }}
              >
                {quickConnect.name.substring(0)}
              </Avatar>
            </Grid>
            <Grid item xs={7}>
              <Typography>{quickConnect.name}</Typography>
            </Grid>
            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                onClick={() => callQuickConnect(index)}
                data-testid={`quick-connect-${index}`}
                sx={{
                  visibility: hoveredIndex === index ? 'visible' : 'hidden',
                  marginRight: hoveredIndex === index ? '0px' : '-35px',
                  transition: 'margin-right 0.3s ease',
                  background: 'none',
                  borderRadius: '50px',
                  width: '35px',
                  height: '35px',
                  boxShadow: 'none',
                  ':hover': { svg: { fill: '#00B900' }, background: '#33373F', boxShadow: 'none' }
                }}
              >
                <TelephoneIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
};

export default QuickConnectsContainer;
