import { Fab } from '@coolblue-development/becky';
import { ArrowLeft, ArrowRight } from '@coolblue-development/icons';

interface ToggleWidthButtonProps {
  widthOptions: number[];
  widthIndex: number;
  onClick: () => void;
}

const ToggleWidthButton = ({ onClick, widthIndex, widthOptions }: ToggleWidthButtonProps): JSX.Element => (
  <Fab onClick={onClick} sx={{ position: 'absolute', left: '-20px', top: '50%', transform: 'translateY(-50%)' }}>
    {widthIndex === widthOptions.length - 1 ? <ArrowRight /> : <ArrowLeft />}
  </Fab>
);

export default ToggleWidthButton;
