import React from 'react';
import { Box, Button } from '@coolblue-development/becky';

interface NumpadProps {
  onButtonClick: (value: string) => void;
}

const Numpad: React.FC<NumpadProps> = ({ onButtonClick }) => {
  const buttons = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        width: '100%',
        gap: '2px',
        marginBottom: '24px'
      }}
    >
      {buttons.map((buttonContent, index) => (
        <Button
          key={index}
          sx={{
            background: '#1C2027',
            boxShadow: 'none',
            fontSize: '21px',
            borderRadius: '2px',
            fontWeight: '400',
            ':hover': { background: '#33373F', boxShadow: 'none' }
          }}
          onClick={() => onButtonClick(buttonContent)}
        >
          {buttonContent}
        </Button>
      ))}
    </Box>
  );
};

export default Numpad;
