import { Box } from '@coolblue-development/becky';
import Channels from './Channels';
import Other from './Other';
import TelephonyWidgetButton from './TelephonyWidgetButton';
import { useAppContext } from '../../../AppContext';

const SideMenu = () => {
  const { telephonyStatusBarOpen } = useAppContext();

  return (
    <>
      <Box>
        <Channels />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: telephonyStatusBarOpen ? '140px' : '90px',
          width: '100%'
        }}
      >
        <Other />
        <TelephonyWidgetButton />
      </Box>
    </>
  );
};

export default SideMenu;
