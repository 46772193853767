import { MouseEvent, useEffect, useState, useRef } from 'react';
import { Button, MenuItem, Menu } from '@coolblue-development/becky';
import { ArrowDown, ArrowUp } from '@coolblue-development/icons';
import { useAppContext } from '../../../AppContext';

const StatusMenu: React.FC = () => {
  const { setTelephonyStatusBarOpen, agentState } = useAppContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [agentStates, setAgentStates] = useState<connect.AgentStateDefinition[]>([]);
  const [stateNames, setStateNames] = useState<string[]>([]);
  const [currentState, setCurrentState] = useState<string>('');
  const nextCurrentStateRef = useRef<connect.AgentState | undefined>(undefined);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  useEffect(() => {
    connect.agent(function (agent) {
      const agentStates = agent.getAgentStates();
      const stateNames = agentStates.map(state => state.name);
      setAgentStates(agentStates);
      setStateNames(stateNames);
      setCurrentState(agent.getAvailabilityState().state);

      agent.onEnqueuedNextState(() => {
        const nextState = agent.getNextState();
        nextCurrentStateRef.current = nextState;
      });

      connect.contact(contact => {
        contact.onDestroy(() => {
          const activeContacts = agent.getContacts();
          if (activeContacts.length === 0 && nextCurrentStateRef.current) {
            setCurrentState(nextCurrentStateRef.current.name);
            nextCurrentStateRef.current = undefined;
          }
        });
      });
    });
  }, []);

  const handleMenuItemClick = (stateName: string): void => {
    const selectedState = agentStates.find(state => state.name === stateName);
    if (!selectedState) {
      return;
    }

    connect.agent(agent => {
      const activeContacts = agent.getContacts();

      if (activeContacts.length === 0 || activeContacts[0]?.getStatus().type === connect.ContactStateType.ENDED) {
        agent.setState(selectedState);
        setCurrentState(stateName);
      } else {
        const agentSetStateOptions: connect.AgentSetStateOptions = {
          enqueueNextState: true
        };

        agent.setState(
          selectedState,
          {
            success: () => {},
            failure: () => {}
          },
          agentSetStateOptions
        );

        setCurrentState('Next status: ' + stateName);
      }
    });

    handleClose();
  };

  useEffect(() => {
    if (agentState?.type === connect.AgentStateType.NOT_ROUTABLE) {
      setTelephonyStatusBarOpen(true);
    } else if (
      agentState?.type === connect.AgentStateType.ROUTABLE ||
      agentState?.type === connect.AgentStateType.OFFLINE
    ) {
      setTelephonyStatusBarOpen(false);
    }
  }, [agentState]);

  return (
    <>
      <Button
        endIcon={open ? <ArrowUp /> : <ArrowDown />}
        sx={{
          marginRight: 3,
          background: 'transparent',
          color: 'white',
          boxShadow: 'none',
          textAlign: 'left',
          '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.3)', boxShadow: 'none' }
        }}
        onClick={handleClick}
        aria-controls={open ? 'default-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {currentState}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'default-button'
        }}
      >
        {stateNames.map((state, index) => (
          <MenuItem key={index} onClick={() => handleMenuItemClick(state)}>
            {state}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default StatusMenu;
