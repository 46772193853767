import React from 'react';
import { Box, Typography, Button, Label } from '@coolblue-development/becky';
import { ContactProps } from '../../../../../models/ContactProps';

const Ended: React.FC<ContactProps> = (props: ContactProps) => {
  const handleDestroyChat = () => {
    props.contact.clear({
      success: function () {},
      failure: function () {}
    });
  };

  return (
    <>
      <Box
        data-testid="ended"
        sx={{ padding: '15px', marginBottom: '8px', borderRadius: '5px', border: '1px solid #E50000' }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5"> Customer </Typography>
          <Label color="red" variant="subtle">
            Missed
          </Label>
        </Box>
        <Button
          sx={{
            marginTop: '15px',
            background: '#E50000',
            boxShadow: 'inset 0 -2px 0 #AA2222',
            ':hover': { background: '#BE0000', boxShadow: 'inset 0 -2px 0 #680B0F' }
          }}
          onClick={handleDestroyChat}
          size="small"
          color="error"
          fullWidth
        >
          Close chat
        </Button>
      </Box>
    </>
  );
};

export default Ended;
