export const LoadingQuotes = [
  'Connecting you to a (friendly) customer',
  'o(>ω<)o',
  'Meow 🐶',
  'Take a deep breath..',
  'Sewyyyyyy',
  "Isn't that concerning?",
  'Bro has a question 🗣️',
  'Can I pet that dawg???? 🐻',
  "Don't add 69 items in your shopping cart in the webshop",
  "🟥 If you see a blue square, you're getting €1000,- Coolblue discount",
  '1 sec',
  "Don't search for a vibrator in the webshop",
  'Stay hydrated 🫵',
  'Komt ie maat',
  '35!!!',
  'SPOW POW',
  "No, we don't sell Fortnite accounts sir"
];
