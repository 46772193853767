import { Box } from '@coolblue-development/becky';
import Close from './TopPanel/Close';

const ChatTopPanel = () => {
  return (
    <Box
      sx={{
        height: '52px',
        borderBottom: '#DDDDDD 1px solid',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '8px',
        paddingRight: '15px'
      }}
    >
      <Close />
    </Box>
  );
};

export default ChatTopPanel;
