import React, { useEffect, useState } from 'react';
import { Label, Typography } from '@coolblue-development/becky';
import TimerIcon from '../../../Icons/TimerIcon';
import { formatDuration } from '../../../../../utils/formatDuration';
interface TimerProps {
  isAfterCallWork: boolean;
  initialDuration: number;
}

const Timer: React.FC<TimerProps> = (props: TimerProps) => {
  const [duration, setDuration] = useState(props.initialDuration);

  useEffect(() => {
    setDuration(props.initialDuration);
    const timerId = setInterval(() => setDuration(prevDuration => prevDuration + 1000), 1000);
    return () => clearInterval(timerId);
  }, [props.initialDuration]);

  return (
    <Label color={props.isAfterCallWork ? 'orange' : 'green'} variant="subtle">
      <TimerIcon color={props.isAfterCallWork ? '#FF6600' : '#008500'} />
      <Typography variant="body1" sx={{ marginLeft: '5px' }}>
        {formatDuration(duration)}
      </Typography>
    </Label>
  );
};

export default Timer;
