import { FeatureFlagInterface } from '../constants';
import { useAppContext } from '../../AppContext';

interface UseFeatureFlagInterface {
  isFeatureFlagEnabled: (name: string) => boolean | undefined;
  updateFeatureFlag: (name: string, enabled: boolean) => void;
  featureFlags: FeatureFlagInterface[];
}
export function useFeatureFlags(): UseFeatureFlagInterface {
  const { setFeatureFlags, featureFlags } = useAppContext();

  const updateFeatureFlag = (name: string, enabled: boolean): void => {
    setFeatureFlags(
      featureFlags.map(featureFlag => {
        if (featureFlag.name === name) {
          return { ...featureFlag, enabled };
        }
        return featureFlag;
      })
    );
  };

  const isFeatureFlagEnabled = (name: string): boolean | undefined => {
    return featureFlags.find(featureFlag => featureFlag.name === name)?.enabled;
  };

  return {
    featureFlags,
    updateFeatureFlag,
    isFeatureFlagEnabled
  };
}
