import { Box, Label, Typography } from '@coolblue-development/becky';
import { CbColorBaseWhite } from '@coolblue-development/design-tokens';
import { useAppContext } from '../../../../../AppContext';
import { useEffect, useState } from 'react';
import { CAPTAIN } from '../../../../../utils/constants';

const ContactPhoneNumber = () => {
  const { thirdPartyVoiceConnection, agentVoiceConnection, voiceContact } = useAppContext();
  const [activeCall, setActiveCall] = useState<string>('');
  const [onHoldCall, setOnHoldCall] = useState<string>('');
  const [agentFullName, setAgentFullName] = useState<string>('');

  useEffect(() => {
    const customerPhoneNumber = voiceContact?.getInitialConnection()?.getEndpoint()?.phoneNumber ?? '';
    const { agentFirstName, agentLastName } = voiceContact?.getAttributes() ?? {};

    const thirdPartyConnectionName =
      thirdPartyVoiceConnection?.getQuickConnectName() ?? thirdPartyVoiceConnection?.getEndpoint()?.phoneNumber;

    if (agentFirstName && agentLastName) {
      connect.agent(agent => {
        if (agent.getRoutingProfile().name.toLowerCase().includes(CAPTAIN)) {
          setAgentFullName(`${agentFirstName.value} ${agentLastName.value}`);
        }
      });
    }

    setActiveCall(thirdPartyVoiceConnection ? thirdPartyConnectionName ?? '' : customerPhoneNumber);
    setOnHoldCall(thirdPartyVoiceConnection && agentVoiceConnection?.isActive() ? customerPhoneNumber : ''); // Check if the connection with the customer is still there
  }, [agentVoiceConnection, thirdPartyVoiceConnection]);

  return (
    <Box>
      <Typography color={CbColorBaseWhite} fontWeight={400} fontSize={24}>
        {agentFullName ? agentFullName : activeCall}
      </Typography>
      {onHoldCall && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }} data-testid="on-hold-call">
          <Typography color="#394046" fontWeight={400} fontSize={16}>
            {onHoldCall}
          </Typography>
          <Label sx={{ background: 'rgba(255, 255, 255, 0.2)', marginLeft: '10px', color: '#666666' }}>HOLD</Label>;
        </Box>
      )}
    </Box>
  );
};

export default ContactPhoneNumber;
