import { Box, Button } from '@mui/material';
import React from 'react';
import { QuickConnectsType } from './QuickConnectsContainer';

interface QuickConnectsFilterButtonProps {
  dataTestId: string;
  label: string;
  type: QuickConnectsType;
  isSelected: boolean;
  setFilter: (filter: QuickConnectsType) => void;
}

const QuickConnectsFilterButton: React.FC<QuickConnectsFilterButtonProps> = ({
  dataTestId,
  label,
  type,
  isSelected,
  setFilter
}) => {
  return (
    <Button
      data-testid={dataTestId}
      sx={{
        color: isSelected ? '#0090e3' : '#fff',
        position: 'relative',
        ':hover': { background: 'none' }
      }}
      onClick={() => setFilter(type)}
    >
      {label}
      {isSelected && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '-11px',
            left: 0,
            right: 0,
            height: '3px',
            backgroundColor: '#0090e3'
          }}
        />
      )}
    </Button>
  );
};

export default QuickConnectsFilterButton;
