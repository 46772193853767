import { currentTime } from './currentTime';

export const addRecentCallsToLocalStorage = (contact: connect.Contact): void => {
  const phoneNumber = contact.getActiveInitialConnection()?.getEndpoint().phoneNumber;
  const isInbound = contact.isInbound();
  const currentTimeStamp = currentTime();

  const recentCalls = JSON.parse(localStorage.getItem('recentCalls') || '[]');

  if (recentCalls.length > 20) {
    recentCalls.pop();
  }

  recentCalls.unshift({ phoneNumber, isInbound, currentTimeStamp });
  localStorage.setItem('recentCalls', JSON.stringify(recentCalls));
};
