export enum IntegrationChannel {
  CATO = 'cato'
}

export enum LookupEntityFieldType {
  TELEPHONE = 'telephone',
  EMAIL = 'email',
  CUSTOMER_ID = 'customerId'
}

// Outgoing message types
export enum ThirdPartyWSMethod {
  SUBSCRIBE = 'SubscribeThirdParty',
  UNSUBSCRIBE = 'UnsubscribeThirdParty',
  NOTIFY_CONVERSATION_OPENED = 'NotifyConversationOpened',
  NOTIFY_CONVERSATION_CLOSED = 'NotifyConversationClosed',
  NOTIFY_THIRD_PARTY_FOCUSED = 'NotifyThirdPartyFocused'
}

type ConversationOpenedPayload = {
  channelConversationId: string;
  channel: IntegrationChannel;
  visualIdentifier?: string;
  lookupCustomer?: {
    fieldType: LookupEntityFieldType;
    fieldValue: string;
  };
  routingGroup?: string;
  conversationSubsidiaryId?: number;
};

type ConversationClosedPayload = {
  channel: IntegrationChannel;
};

export type ThirdPartyFocusedPayload = {
  thirdPartyName: string;
};

type SubscribePayload = {
  thirdPartyName: string;
};
type UnsubscribePayload = Record<string, never>;

export type NotifyConversationOpenedPayload = ConversationOpenedPayload;

type NotifyConversationClosedPayload = ConversationClosedPayload;

type NotifyConversationFocusedPayload = ThirdPartyFocusedPayload;

export type OutgoingThirdPartyConnectorEventPayloads = {
  SubscribeThirdParty: SubscribePayload;
  UnsubscribeThirdParty: UnsubscribePayload;
  NotifyConversationOpened: NotifyConversationOpenedPayload;
  NotifyConversationClosed: NotifyConversationClosedPayload;
  NotifyThirdPartyFocused: NotifyConversationFocusedPayload;
};

export type OutgoingEventPromiseType = {
  SubscribeThirdParty: undefined;
  UnsubscribeThirdParty: undefined;
  NotifyConversationOpened: undefined;
  NotifyConversationClosed: undefined;
  NotifyThirdPartyFocused: undefined;
};
