import { Box, Typography } from '@coolblue-development/becky';

import CassieShowButton from '../../CassieContainer/CassieShowButton';
import { FeatureFlag } from '../../constants';
import React from 'react';
import { useAppContext } from '../../../AppContext';
import { useFeatureFlags } from '../../ToolkitPanel/useFeatureFlags';

const TopPanel: React.FC = () => {
  const { activePage } = useAppContext();

  const { isFeatureFlagEnabled } = useFeatureFlags();
  const isCassieStandaloneAppEnabled = isFeatureFlagEnabled(FeatureFlag.CASSIE_STANDALONE_APP);

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'space-between', padding: '8px 16px', borderBottom: '#DDDDDD 1px solid' }}
    >
      <Typography sx={{ fontSize: '2rem', fontWeight: '700', lineHeight: '3.5rem', wordWrap: 'break-word' }}>
        {activePage}
      </Typography>
      {isCassieStandaloneAppEnabled && <CassieShowButton />}
    </Box>
  );
};

export default TopPanel;
