const postEvent = async (id: string, type: string, details: unknown) => {
  const url = `${process.env.REACT_APP_CATO_API_URL as string}/${id}/events/${type}`;
  const currentDateTime = new Date().toISOString();
  const body = JSON.stringify({ dateTime: currentDateTime, details: details });

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body
    });

    if (!response.ok) {
      throw new Error(`Status: ${response.status}`);
    }

    return await response.text();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default postEvent;
