import * as React from 'react';
import { datadogLogs } from '@datadog/browser-logs';

import { useCallback, useEffect, useState } from 'react';

const useAppBrowserLock = (
  lockName: string,
  autoLock: boolean
): {
  isLocked: boolean;
  activeLock: { name: string; releaseLock: () => void } | null;
  requestLock: () => void;
} => {
  const [shouldAutoLock, setShouldAutoLock] = useState<boolean>(autoLock);
  const [lockId] = useState<string>(crypto.randomUUID());
  const [activeLock, setActiveLock] = useState<{
    name: string;
    releaseLock: () => void;
  } | null>(null);

  // Lock name with unique id for the current hook instance
  const lockNameWithId = `${lockName}_lock_${lockId}`;

  // isLockedInBrowser has 3 potential states: true, false, null
  // true: lock is held
  // false: lock is not held
  // null: lock status is unknown (to be queried)
  const [isLockedInBrowser, setIsLockedInBrowser] = useState<boolean | null>(null);

  // Mounted state for safe async setter calls
  const [isMounted, setIsMounted] = useState<boolean>(true);
  React.useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  // Main request lock function
  // Can only request a lock if the lock snapshot has been queried thus isLockedInBrowser is not null
  const requestLock = useCallback(async () => {
    // Lock status is unknown
    // or lock is already held
    // can't request lock
    if (isLockedInBrowser === null || isLockedInBrowser === true) {
      return;
    }

    // Lock not active, requesting the lock

    // Locking promise to be resolved when the lock need to be released
    const lockingPromise = new Promise<void>(resolve => {
      if (isMounted) {
        setActiveLock({ name: lockNameWithId, releaseLock: resolve });
        setIsLockedInBrowser(true);
      }
    });

    try {
      await navigator.locks.request(lockNameWithId, { mode: 'exclusive' }, async () => lockingPromise);
    } catch (error) {
      datadogLogs.logger.warn(`Error obtaining lock ${lockName}`, { integration: 'cassie', standalone: true });
    }
  }, [isLockedInBrowser, isMounted, lockNameWithId, lockName]);

  // When the lock snapshot has been queried, we can request a lock if it's not held already
  React.useEffect(() => {
    if (isLockedInBrowser === false && shouldAutoLock) {
      void requestLock();
    }
  }, [isLockedInBrowser, requestLock, shouldAutoLock]);

  // To be able to allow a request, we first want to query if the lock is already held
  // isLockedInBrowser set to null, triggers the query
  React.useEffect(() => {
    if (isLockedInBrowser === null) {
      try {
        void navigator.locks.query().then(res => {
          setIsLockedInBrowser(!!res.held?.find(l => l.name === lockNameWithId));
        });
      } catch (error) {
        datadogLogs.logger.warn(`Error obtaining lock ${lockName}`, { integration: 'cassie', standalone: true });
      }
    }
  }, [isLockedInBrowser, lockName, lockNameWithId]);

  // Manual request lock function (used when autoLock is false)
  const manualRequestLock = useCallback(() => {
    // Lock status is known and not held, request it
    if (isLockedInBrowser === false) {
      void requestLock();
    }
    // Lock status is unknown, yet
    // Set shouldAutoLock to true to request the lock as soon as the lock status is known
    else if (isLockedInBrowser === null) {
      setShouldAutoLock(true);
    }
  }, [isLockedInBrowser, requestLock]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (activeLock) activeLock.releaseLock();
    };
  }, [activeLock]);

  return {
    isLocked: !!activeLock,
    activeLock,
    requestLock: manualRequestLock
  };
};

export default useAppBrowserLock;
