import { Box, Divider } from '@mui/material';
import React from 'react';
import { QuickConnectsType } from './QuickConnectsContainer';
import QuickConnectsFilterButton from './QuickConnectsFilterButton';
import { DEPARTMENTS } from '../../../../../utils/constants';
import { IsB2bAgent } from '../../../../../utils/isB2bAgent';
import BackButton from './BackButton';

interface QuickConnectsHeaderProps {
  filter: QuickConnectsType;
  setFilter: (filter: QuickConnectsType) => void;
}

const QuickConnectsHeader: React.FC<QuickConnectsHeaderProps> = ({ filter, setFilter }) => {
  return (
    <>
      <Box sx={{ display: 'flex', marginBottom: '10px', alignItems: 'center', gap: '10px' }}>
        <BackButton />
        {IsB2bAgent() && (
          <>
            <Box sx={{ borderLeft: '#394046 1px solid', height: '23px' }} />
            <QuickConnectsFilterButton
              dataTestId="departments-filter-button"
              label="Departments"
              type={DEPARTMENTS}
              isSelected={filter === DEPARTMENTS}
              setFilter={setFilter}
            />
            <QuickConnectsFilterButton
              dataTestId="agents-filter-button"
              label="Agents"
              type={connect.EndpointType.AGENT}
              isSelected={filter === connect.EndpointType.AGENT}
              setFilter={setFilter}
            />
          </>
        )}
      </Box>
      <Divider sx={{ background: '#394046' }} />
    </>
  );
};

export default QuickConnectsHeader;
