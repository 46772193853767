import React from 'react';
import Box from '@mui/material/Box';
import { IconButton } from '@coolblue-development/becky';
import CloseIcon from '../../../Icons/CloseIcon';
import { CircularProgress } from '@mui/material';

interface ImagePreviewProps {
  selectedImage: File | null;
  onRemoveImage: () => void;
  loader: boolean;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ selectedImage, onRemoveImage, loader }) => (
  <Box
    sx={{
      position: 'absolute',
      top: '10px',
      left: '10px',
      width: '80px',
      height: '80px',
      overflow: 'hidden',
      borderRadius: '6px',
      border: '#DDDDDD 1px solid'
    }}
  >
    {loader ? (
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgba(0,0,0,0.7)',
          zIndex: 2
        }}
        data-testid="loader"
      >
        <CircularProgress />
      </Box>
    ) : null}
    <IconButton
      data-testid="remove-image-button"
      onClick={onRemoveImage}
      preset="primary"
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        margin: '5px',
        padding: '4px',
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        boxShadow: 'none'
      }}
      size="small"
    >
      <CloseIcon />
    </IconButton>
    <img
      src={selectedImage ? URL.createObjectURL(selectedImage) : ''}
      style={{
        objectFit: 'contain',
        width: '100%',
        height: '100%'
      }}
    />
  </Box>
);

export default ImagePreview;
