import { Box } from '@coolblue-development/becky';
import TelephonySidePanel from './TelephonySidePanel';
import TelephonyTopPanel from './TelephonyTopPanel';
import { useState } from 'react';

interface RecentCall {
  phoneNumber: string;
  index: number;
}

const TelephonyPage = () => {
  const [selectedRecentCall, setSelectedRecentCall] = useState<RecentCall>({ phoneNumber: '', index: 0 });

  const handleSelectedRecentCall = (phoneNumber: string, index: number) => {
    setSelectedRecentCall({ phoneNumber, index });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', height: '100vh', paddingBottom: '120px' }}>
      <TelephonySidePanel handleSelectedRecentCall={handleSelectedRecentCall} />
      <TelephonyTopPanel selectedRecentCall={selectedRecentCall} />
    </Box>
  );
};

export default TelephonyPage;
