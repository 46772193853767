import { ReactComponent as DutchFlag } from '../assets/dutchFlag.svg';
import { ReactComponent as BelgianFlag } from '../assets/belgianFlag.svg';
import { ReactComponent as GermanFlag } from '../assets/germanFlag.svg';
import { ReactComponent as LuxembourgFlag } from '../assets/luxembourgFlag.svg';
import { ReactComponent as AustrianFlag } from '../assets/austrianFlag.svg';
import { ReactComponent as BulgarianFlag } from '../assets/bulgarianFlag.svg';
import { ReactComponent as RomanianFlag } from '../assets/romanianFlag.svg';
import { ReactComponent as DanishFlag } from '../assets/danishFlag.svg';
import { ReactComponent as PolishFlag } from '../assets/polishFlag.svg';
import { ReactComponent as FrenchFlag } from '../assets/frenchFlag.svg';
import { ReactComponent as CzechFlag } from '../assets/czechFlag.svg';
import { CountryCode } from 'libphonenumber-js';

export interface Country {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  code: string;
  country: CountryCode;
  isB2b: boolean;
}

export const phoneCountryCodes: Country[] = [
  { icon: DutchFlag, code: '+31', country: 'NL', isB2b: false },
  { icon: BelgianFlag, code: '+32', country: 'BE', isB2b: false },
  { icon: GermanFlag, code: '+49', country: 'DE', isB2b: false },
  { icon: FrenchFlag, code: '+33', country: 'FR', isB2b: true },
  { icon: LuxembourgFlag, code: '+352', country: 'LU', isB2b: true },
  { icon: CzechFlag, code: '+420', country: 'CZ', isB2b: true },
  { icon: DanishFlag, code: '+45', country: 'DK', isB2b: true },
  { icon: AustrianFlag, code: '+43', country: 'AT', isB2b: true },
  { icon: PolishFlag, code: '+48', country: 'PL', isB2b: true },
  { icon: BulgarianFlag, code: '+359', country: 'BG', isB2b: true },
  { icon: RomanianFlag, code: '+40', country: 'RO', isB2b: true }
];
